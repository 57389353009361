.footerCont {
  @apply bg-black pt-16 pb-6 px-4 w-full;
}

.footer-menu {
  @apply flex flex-col lg:flex-row lg:space-x-9 lg:justify-center;
  li {
    a {
      @apply text-white hover:text-gray text-sm lg:text-base;
    }
  }
}

.footer-socials {
  @apply flex space-x-4 justify-center;
}

@media (min-height: 1100px) {
  .footerForShortPages {
    @apply absolute bottom-0 left-0 w-full;
  }
}
