@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .reference-usecase-1 {
    background-image: url("../public/img/uc_head_off.jpg");
    mask-image: linear-gradient(rgba(0, 0, 0, 2) 7%, rgba(0, 0, 0, 0));
  }

  .reference-usecase-2 {
    background-image: url("../public/img/uc_head_mob.jpg");
    mask-image: linear-gradient(rgba(0, 0, 0, 1) 7%, rgba(0, 0, 0, 0));
  }
}

@import url(component-styles/common.scss);
@import url(component-styles/header.scss);
@import url(component-styles/banner.scss);
@import url(component-styles/footer-banner.scss);
@import url(component-styles/footer.scss);

// sections
@import url(component-styles/section-logos.scss);
@import url(component-styles/section-features.scss);
@import url(component-styles/section-focus.scss);
@import url(component-styles/section-reviews.scss);
@import url(component-styles/section-quote.scss);
@import url(component-styles/section-impressum.scss);
@import url(component-styles/section-pricing.scss);
@import url(component-styles/section-tax.scss);
@import url(component-styles/widget.scss);

body {
  overflow-x: hidden;
  // background-image: linear-gradient(180deg, #fff8ef, #fff),
  //   linear-gradient(0deg, #fff, #fff);
}

.select-tag {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-image: url("../public/img/arrow.svg");
  -webkit-background-position: right 19px top 50%;
  background-position: right 19px top 50%;
  background-repeat: no-repeat;
}