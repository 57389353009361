.section-focus {
    .focuses {
        @apply grid grid-cols-1 lg:grid-cols-3 grid-flow-row gap-0;
    }
    .focus {
        @apply py-10 px-14 text-center flex flex-col items-center hover:shadow-2xl hover:text-orange;
    }

    .focus:first-child {
        @apply lg:border-r border-gray;
    }

    .focus:last-child {
        @apply lg:border-l border-gray;
    }

    .focuses:first-child {
        @apply lg:border border-b-2;
    }

    .focus-image {
        @apply w-36 mx-auto lg:w-1/6 flex-none;
        img {
            @apply mx-auto;
        }
    }

    .focus-title {
        @apply py-6 font-bold text-xl lg:text-2xl leading-6;
    }

    .focus-description {
        @apply font-normal leading-7 text-base lg:text-lg text-gray;
    }
}