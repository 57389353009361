.section-features {
    .features {
        @apply space-y-8;
    }
    .feature {
        @apply flex space-y-10 lg:space-y-0 lg:space-x-16;
    }

    .feature:nth-child(odd) {
        @apply flex-col-reverse lg:flex-row;
    }

    .feature:nth-child(even) {
        @apply flex-col lg:flex-row
    }

    .feature-content {
        @apply flex-1 flex flex-col justify-center items-start;
        .feature-title {
            @apply text-3xl lg:text-4xl font-bold pb-3 lg:pb-10;
        }
        .feature-description {
            @apply pb-9 lg:pb-10 text-lg text-gray;
        }
    }

    .feature-image {
        @apply flex-1 pb-6 lg:pb-0;
        border-radius: 30px;
    }
}