.section-pricing {
  .pricing {
    border: 1px solid rgb(219, 219, 219);
  }

  .pricing-btn {
    border: 2px solid rgb(251 93 30);
  }

  .pricing-btn:hover {
    border: 2px solid rgb(251 93 30);
    background-color: rgb(251 93 30);
  }

  .pricing-btnTest {
    border: 2px solid rgba(255, 255, 255, 0);
  }

  .pricing-btnTest:hover {
    border: 2px solid rgb(251 93 30);
    background-color: transparent;
  }

  .main-pricing-flex {
    max-width: 1536px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .popular-tag {
    transform: translateX(-50%);
  }

  .pricing-cards-flex {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px;
  }

  @media (max-width: 1280px) {
    .main-pricing-flex {
      max-width: 1280px;
    }
  }

  @media (max-width: 1247px) {
    .leftbar-pricing {
      display: none;
    }

    .pricing {
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
    }

    .pricing-cards-flex {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    .names-pricing-f {
      display: block;
    }
  }

  @media (max-width: 987px) {
    .pricing-cards-flex {
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
  }

  @media (max-width: 659px) {
    .pricing-cards-flex {
      grid-template-columns: 1fr;
      gap: 60px;
    }
  }

  @media (max-width: 670px) {
    .main-pricing-flex {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
